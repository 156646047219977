















import { Component, Vue } from "vue-property-decorator";
import api from "@/api";

import zhike from "@/views/integral/components/zhike.vue";
import broker from "@/views/integral/components/broker.vue";
import changkou from "@/views/integral/components/changkou.vue";

@Component({
  components: {
    zhike,
    broker,
    changkou
  }
})
export default class AUser extends Vue {
  activeName = '0'
  config = {
    jiaoyi: {
      0: '冻结',
      1: '入账',
      2: '兑换'
    },
    type: {
      0: '会员',
      1: '经纪人'
    },
    status: {
      0: '未核销',
      1: '已核销'
    }
  }
  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  // 总条数
  total = 0;
  // 搜索条件
  jifenType = '直客积分'
  search: any = {
    keyword: '',
    userId: '',
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };

  //列表状态查询
  jifenConfig = {}
  getStatusEnum() {
    api
      .post("J27110", {keyword: '3'})
      .then((data) => {
        this.jifenConfig = data
      });
  }

  activated() {
    if(this.$route.query.from == 'CK') {
      this.activeName = '2'
    } else if(this.$route.query.from == 'zhike') {
      this.activeName = '0'
    } else if(this.$route.query.from == 'broker') {
      this.activeName = '1'
    }
    this.search.userId = this.$route.query.userId
    this.getStatusEnum()
  }
}
